export default {
  // LOẠI CHẤN THƯƠNG
  API_NOTIFICATION: 'NotificationTraining/notificationTrainings',
  CREATE_NOTIFICATION: 'NotificationTraining/notificationTrainings',
  EDIT_NOTIFICATION: 'NotificationTraining/notificationTrainings',
  DELETE_NOTIFICATION: 'NotificationTraining/delete-notification-trainings',
  DETAIL_NOTIFICATION: 'NotificationTraining/notificationTrainings/',
  DOWLOAD_FILE: 'NotificationTraining/download-file',
  EXPORT_EXCEL: 'NotificationTraining/export-excel',
}
