<template>
  <div id="filter-custom">
    <h3
      class="header"
    >
      Bộ lọc
    </h3>
    <b-row>
      <!-- Người lao động -->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group>
          <label
            for="basicInput"
          >Loại hình doanh nghiệp<span class="required"></span></label>
          <v-select
            v-model="valueBusiness"
            :reduce="label => label.id"
            label="name"
            :options="business"
            :placeholder="'Loại hình doanh nghiệp'"
            @input="filterDistrict"
          >
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,

  },
  data() {
    return {
      valueBusiness: '',
      business: [],
      apiCombobox: 'Bussiness/get-combobox-bussiness',
    }
  },
  created() {
    this.fetchCombobox()
  },
  methods: {
    async fetchCombobox() {
      const { data } = await axiosApiInstance.get(this.apiCombobox)
      this.business = data?.data
    },
    filterDistrict() {
      this.$emit('filterDistrict', this.valueBusiness)
    },
  },
}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
}
</style>
